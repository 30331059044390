import React from "react"
import Page from "../components/page/Page"

export default (props) => {
  return (
    <Page class="404" slug="404" title="404">
      <article className="article">
        <header className="article-header">
          <h1 className="title">404</h1>
        </header>
        <main className="article-main">Página no encontrada</main>
      </article>
    </Page>
  )
}
